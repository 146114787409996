/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
import { lessThanZore, greaterThanZore } from '@/modules/mdm/utils/regList.js'
// 表单配置信息
export const collapse = [
    {
        title: '基本信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 4,
        labelWidth: '150px',
        visible: true,
        formList: [
            {
                label: '单据编号',
                prop: 'contractCode',
                value: 'contractCode',
                type: 'custom',
            },
            {
                label: '法务合同编号',
                prop: 'mipContractCode',
                value: 'mipContractCode',
                type: 'custom'
            },
            {
                label: '片区',
                prop: 'region',
                value: 'region',
                type: 'custom'
            },
            {
                label: '分公司',
                prop: 'companyName',
                value: 'companyName',
                type: 'custom'
            },
            {
                label: '分公司编码',
                prop: 'companyCode',
                value: 'companyCode',
                type: 'custom'
            },
            {
                label: '服务平台',
                prop: 'siteName',
                value: 'siteName',
                type: 'custom'
            },
            {
                label: '客户编码',
                prop: 'customerCode',
                value: 'customerCode',
                type: 'custom'
            },
            {
                label: '客户名称',
                prop: 'customerName',
                value: 'customerName',
                type: 'custom'
            },
            {
                label: '客户等级',
                prop: 'calCustomerLevel',
                value: 'calCustomerLevel',
                multiple: true,
                type: 'custom',
                optionsKey: 'NEOCRM_CUSTOMER_LEVEL',
            },
            {
                label: '行业大类',
                prop: 'industryCategories',
                value: 'industryCategories',
                type: 'custom'
            },
            {
                label: '细分行业',
                prop: 'industryType',
                value: 'industryType',
                type: 'custom'
            },
            {
                label: '客户系名称',
                prop: 'customerGroupName',
                value: 'customerGroupName',
                type: 'custom'
            },
            {
                label: '业务类型',
                prop: 'businessType',
                value: 'businessType',
                multiple: true,
                optionsKey: 'SYS_BMS_BUSI_TYPE',
                type: 'custom'
            },
            {
                label: '商机号',
                prop: 'opportunityCode',
                value: 'opportunityCode',
                type: 'custom'
            },
            {
                label: '业务结构（仓库类型）',
                prop: 'warehouseType',
                value: 'warehouseType',
                type: 'custom',
                multiple: true,
                optionsKey: 'SYS_BMS_BUSI_TYPE'
            },
            {
                label: '生效日期',
                prop: 'validTime',
                value: 'validTime',
                type: 'custom'
            },
            {
                label: '失效日期',
                prop: 'failTime',
                value: 'failTime',
                type: 'custom'
            },
            {
                label: '合同状态',
                prop: 'contractStatus',
                value: 'contractStatus',
                optionsKey: 'SYS_CR_CONTRACT_STATUS',
                type: 'custom'
            },
            {
                label: '合同性质',
                prop: 'contractCharacter',
                value: 'contractCharacter',
                optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
                type: 'custom'
            },
            {
                label: '续签新合同号',
                prop: 'renewalContractCode',
                value: 'renewalContractCode',
                type: 'custom'
            },
            {
                label: '续签生效日期',
                prop: 'renewalValidTime',
                value: 'renewalValidTime',
                type: 'custom'
            },
            {
                label: '续签失效日期',
                prop: 'renewalFailTime',
                value: 'renewalFailTime',
                type: 'custom'
            },
            {
                label: '经营中心利润率(%)',
                prop: 'operatGrossMarginPlan',
                value: 'operatGrossMarginPlan',
                type: 'custom'
            },
            {
                label: '分公司营销经理',
                prop: 'marketingManager',
                value: 'marketingManager',
                type: 'custom'
            },
            {
                label: '分公司续签对接人',
                prop: 'renewalManager',
                value: 'renewalManager',
                type: 'custom'
            },
            {
                label: '关联主合同编号',
                prop: 'mainContract',
                value: 'mainContract',
                type: 'custom'
            },
            {
                label: '创建人',
                prop: 'createUserName',
                value: 'createUserName',
                type: 'custom'
            },
            {
                label: '创建时间',
                prop: 'createTime',
                value: 'createTime',
                type: 'custom'
            },
            {
                label: '修改人',
                prop: 'updateUserName',
                value: 'updateUserName',
                type: 'custom'
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                value: 'updateTime',
                type: 'custom'
            },
            {
                label: '变更续签承接人记录',
                prop: 'changeRecord',
                value: 'changeRecord',
                type: 'custom'
            },
            {
                label: '变更续签承接人时间',
                prop: 'changeRecordTime',
                value: 'changeRecordTime',
                type: 'custom'
            },
            {
                label: '待办处理人',
                prop: 'scheduleHandlePerson',
                value: 'scheduleHandlePerson',
                type: 'custom'
            },
            {
                label: '待办处理时间',
                prop: 'scheduleHandlePersonTime',
                value: 'scheduleHandlePersonTime',
                type: 'custom'
            }
        ]
    },
    {
        title: '关键信息',
        ref: 'importantForm',
        formName: 'importantInfo',
        col: 4,
        labelWidth: '150px',
        visible: true,
        formList: [
            {
                label: '续签状态',
                prop: 'renewalStatus',
                value: 'renewalStatus',
                type: 'select',
                optionsKey: 'CRM_CONTRACT_RENEWAL_STATUS',
                rule: [
                    { required: false, message: '续签状态不能为空', trigger: 'change' }
                ]
            },
            {
                label: '续签风险',
                prop: 'renewalRisk',
                value: 'renewalRisk',
                type: 'select',
                optionsKey: 'CRM_CONTRACT_RENEWAL_RISK',
                rule: [
                    { required: false, message: '续签状态不能为空', trigger: 'change' }
                ]
            },
            {
                label: '风险原因',
                prop: 'riskCause',
                type: 'select',
                multiple: true,
                optionsKey: 'CRM_CONTRACT_RENEWAL_RISK_CAUSE',
                rule: [
                    { required: false, message: '风险原因不能为空', trigger: 'change' }
                ]
            },
            {
                label: '终止原因',
                prop: 'terminationCause',
                value: 'terminationCause',
                type: 'input',
                width: 140,
                rule: [
                    { required: false, message: '终止原因不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '主动/被动退出',
                prop: 'exitStatus',
                value: 'exitStatus',
                type: 'select',
                optionsKey: 'CRM_CONTRACT_EXIT_STATUS',
                rule: [
                    { required: false, message: '主动/被动退出不能为空', trigger: ['change'] }
                ]
            },
            {
                label: '终止原因大类',
                prop: 'causeCategories',
                value: 'causeCategories',
                type: 'select',
                // optionsKey: 'CRM_CONTRACT_CAUSE_CATEGORIES',
                options: {},
                rule: [
                    { required: false, message: '终止原因大类不能为空', trigger: ['change'] }
                ]
            },
            {
                label: '终止原因小类',
                prop: 'causeSubcategory',
                value: 'causeSubcategory',
                type: 'select',
                // optionsKey: 'CRM_CONTRACT_CAUSE_SUBCATEGORY',
                options: {},
                rule: [
                    { required: false, message: '终止原因小类不能为空', trigger: ['change'] }
                ]
            },
            {
                label: '项目进度',
                prop: 'projectSchedule',
                value: 'projectSchedule',
                type: 'input',
                width: 140,
                rule: [
                    { required: false, message: '项目进度不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '上游涨降',
                prop: 'upstreamRenewal',
                value: 'upstreamRenewal',
                type: 'select',
                optionsKey: 'CRM_CONTRACT_RENEWAL_UPSTREAM',
                rule: [
                    { required: false, message: '上游涨降不能为空', trigger: ['change'] }
                ],
                content: '如果选择”线路/标段更换“选项，“上游涨降幅度(%)”字段填写本次续签采毛对比历史的采毛涨降幅度'
            },
            {
                label: '上游涨降幅度(%)',
                prop: 'upstreamAmplitude',
                value: 'upstreamAmplitude',
                type: 'input',
                width: 140,
                rule: [
                    { required: false, message: '上游降价/涨价幅度不能为空', trigger: ['change', 'blur'] },
                    {
                        pattern: '',
                        message: '',
                        trigger: 'blur'
                    }
                ],
                content: '涨价填正，降价填负'
            },
            {
                label: '下游涨降',
                prop: 'downstreamRenewal',
                value: 'downstreamRenewal',
                type: 'select',
                optionsKey: 'CRM_CONTRACT_RENEWAL_DOWNSTREAM',
                rule: [
                    { required: false, message: '下游涨降续签不能为空', trigger: ['change'] }
                ]
            },
            {
                label: '下游涨降幅度(%)',
                prop: 'downstreamAmplitude',
                value: 'downstreamAmplitude',
                type: 'input',
                rule: [
                    { required: false, message: '下游降价/涨价幅度不能为空', trigger: ['change', 'blur'] },
                    {
                        pattern: '',
                        message: '',
                        trigger: 'blur'
                    }
                ],
                content: '涨价填正，降价填负'
            },
            {
                label: '提前终止时间',
                prop: 'earlyTerminationTime',
                value: 'earlyTerminationTime',
                type: 'time',
                timeType: 'date',
                rule: [
                    { required: false, message: '提前终止时间不能为空', trigger: 'change' }
                ],
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd'
            },
            {
                label: '提前续签/延期时间',
                prop: 'advanceOrDelayTime',
                value: 'advanceOrDelayTime',
                type: 'time',
                timeType: 'date',
                rule: [
                    { required: false, message: '提前续签/延期时间不能为空', trigger: 'change' }
                ],
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd'
            },
            {
                label: '续签承接人',
                prop: 'renewalContractor',
                value: 'renewalContractor',
                type: 'advance',
                rule: [
                    { required: false, message: '续签承接人不能为空', trigger: ['change', 'blur'] }
                ],
                advanceConfig: [
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusLoginName', 'value': '美信号' }
                ],
                tableConfig: [
                    { 'prop': 'esusId', 'value': 'ID' },
                    { 'prop': 'esusLoginName', 'value': '美信号' },
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusPositionName', 'value': '职位' },
                    { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/selectUserCompany`,
                cbParams: ['esusUserNameCn#renewalContractor', 'esusLoginName#renewalContractorMip'],
                beforeRequest: (params) => {
                    const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                    const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                    if (reg.test(inputKey)) {
                        params.esusUserNameCn = inputKey;
                        params.esusLoginName = null;
                    } else {
                        params.esusLoginName = inputKey;
                        params.esusUserNameCn = null;
                    }
                    return params;
                }
            },
            {
                label: '外部业务退出报告流程链接',
                prop: 'processLink',
                value: 'processLink',
                type: 'input',
                span: 24,
                rule: [
                    { required: false, message: '外部业务退出报告流程链接不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '备注',
                prop: 'remark',
                value: 'remark',
                type: 'input',
                sourceType: 'input',
                inputType: 'textarea',
                span: 24,
                rows: 3,
                rule: [
                    { required: false, message: '备注不能为空', trigger: ['change', 'blur'] }
                ]
            },
            {
                label: '附件',
                prop: 'file',
                value: 'file',
                type: 'custom',
                span: 24,
                rule: [
                    {
                        required: false,
                        validator: (rule, value, callback) => {
                            if (rule.required && !value.length) {
                                callback(new Error('附件不能为空'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
            }
        ]
    }
];

// 涨价涨幅校验规则
export const upstreamAmplitudeRule = {
    1: // 涨价续签
    {
        required: true,
        pattern: greaterThanZore,
        message: '上游涨降为涨价时，上游涨降幅度(%)需大于0',
        trigger: 'blur'
    },
    2: // 降价续签
    {
        required: true,
        pattern: lessThanZore,
        message: '上游涨降为降价时，上游涨降幅度(%)需小于0'
    },
    3: // 原价续签
        { required: false, message: '上游涨降幅度不能为空' },
    4: // 涨价投标
    {
        required: true,
        pattern: greaterThanZore,
        message: '上游涨降为涨价时，上游涨降幅度(%)需大于0',
        trigger: 'blur'
    },
    5: // 降价投标
    {
        required: true,
        pattern: lessThanZore,
        message: '上游涨降为降价时，上游涨降幅度(%)需小于0'
    },
    6: { required: false, message: '上游涨降幅度不能为空' }, // 原价投标
    7: { required: false, message: '上游涨降幅度不能为空' } // 线路/标段更换
};
// 降价降幅规则
export const downstreamAmplitudeRule = {
    1: // 降价
    {
        required: true,
        pattern: lessThanZore,
        message: '下游涨降为降价时，下游涨降幅度(%)需小于0',
        trigger: 'blur'
    },
    2: // 涨价
    {
        required: true,
        pattern: greaterThanZore,
        message: '下游涨降为涨价时，下游涨降幅度(%)需大于0'
    },
    3: { required: false, message: '下游涨降幅度不能为空' } // 原价	

};