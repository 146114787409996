var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract-registration-detail-page",attrs:{"v-loading":_vm.detailLoading}},[_c('div',{staticClass:"detail-toolBar"},[_c('lots-button',{directives:[{name:"has",rawName:"v-has",value:(_vm.authorityCode.save),expression:"authorityCode.save"}],attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.saveHeader}},[_vm._v("保 存 ")])],1),_c('collapse-infos',{ref:"upholdHealder",attrs:{"detailData":_vm.detailDataLocal,"collapse":_vm.COLLAPSE,"activeCollapses":_vm.activeCollapses},on:{"change":_vm.handleInfosChange},scopedSlots:_vm._u([{key:"collapse-append",fn:function(ref){
var form = ref.form;
var itemSetting = ref.itemSetting;
var rIndex = ref.rIndex;
var collapseItem = ref.collapseItem;
var dictData = ref.dictData;
return [(itemSetting.prop === 'file')?_c('span',[_c('lots-upload-button',{attrs:{"text":'上传附件',"bucket":'annto-lcrm',"disabled":_vm.readonly},on:{"callback":function (data) { _vm.upLoadData(form, itemSetting, data); }}}),_c('div',{staticClass:"file-infos"},[(form[itemSetting.prop]
                        && form[itemSetting.prop].length)?_vm._l((form[itemSetting.prop]),function(item,index){return _c('div',{key:index + item.fileName,staticClass:"file-list"},[_c('div',{staticClass:"file-name upload",attrs:{"title":item.fileName}},[_vm._v(" "+_vm._s(((index+1) + ". "))+" "),_c('a',{attrs:{"href":item.fileUrl,"target":"_blank"}},[_vm._v(_vm._s(item.fileName))])]),_c('div',{staticClass:"delete-btn"},[_c('el-button',{attrs:{"type":"text","plain":""}},[_c('a',{attrs:{"href":item.fileUrl,"target":"_blank"}},[_vm._v("下载")])]),_c('el-button',{attrs:{"type":"text","disabled":_vm.readonly,"plain":""},on:{"click":function($event){return _vm.handleDeleteFile(form, itemSetting, index)}}},[_vm._v("删除")])],1)])}):_vm._e()],2)],1):(!itemSetting.optionsKey)?_c('span',{staticClass:"custom-span",attrs:{"title":form[itemSetting.prop]}},[_vm._v(" "+_vm._s((form[itemSetting.prop] || form[itemSetting.prop] === 0) ? form[itemSetting.prop] : '--')+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }