/* eslint-disable max-lines */
import utils from '@/components/utils/common.js';
const halfYearLater = () => {
    // 获取当前日期
    const now = new Date();
    // 设置半年后的日期
    const halfYearLater = new Date(now.setMonth(now.getMonth() + 6));
    return utils.formatDate(halfYearLater);
};

export const config = {
    name: 'contractRegistration',
    searchFields: [
        {
            name: '单据编号(支持多个)',
            value: 'contractCodes',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '分公司',
            value: 'companyName',
            type: 'advanceMulti',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'companyName', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'region', 'value': '片区' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyName', 'value': '分公司名称', 'displayName': 'companyName' },
                { 'prop': 'region', 'value': '片区' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/company/manager/archive/page`,
            method: 'post',
            cbParams: ['companyCode', 'companyName']
        },
        {
            name: '行业类型',
            value: 'industryTypeName',
            type: 'advanceMulti',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'industryTypeName', 'value': '行业类型名称' },
                { 'prop': 'industryTypeCode', 'value': '行业类型编码' },
                { 'prop': 'industryCategoriesName', 'value': '行业大类名称' },
                { 'prop': 'industryCategoriesCode', 'value': '行业大类编码' }
            ],
            tableConfig: [
                { 'prop': 'industryTypeCode', 'value': '行业类型编码' },
                { 'prop': 'industryTypeName', 'value': '行业类型名称', 'displayName': 'industryTypeName' },
                { 'prop': 'industryCategoriesCode', 'value': '行业大类编码' },
                { 'prop': 'industryCategoriesName', 'value': '行业大类名称' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/industry/info/page`,
            method: 'post',
            cbParams: ['industryTypeCode', 'industryTypeName']
        },
        {
            name: '客户等级(计算)',
            value: 'calCustomerLevelCodes',
            type: 'select',
            optionNum: 'NEOCRM_CUSTOMER_LEVEL',
            isFixed: true,
            multiple: true,
            span: 4
        },
        {
            name: '合同失效日期',
            value: 'failTime',
            type: 'time',
            time: true,
            startPlaceholder: '失效日期从',
            endPlaceholder: '失效日期到',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            isFixed: true,
            span: 6
        },
        {
            name: '续签状态',
            value: 'renewalStatusList',
            type: 'select',
            // optionNum: 'CRM_CONTRACT_RENEWAL_STATUS',
            options: [
                { value: '1', label: '洽谈中' },
                { value: '2', label: '已续签' },
                { value: '3', label: '已终止' },
                { value: '0', label: '空' }
            ],
            isFixed: true,
            multiple: true,
            span: 3
        },
        {
            name: '续签承接人',
            value: 'renewalContractor',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名', 'displayName': 'esusUserNameCn' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#renewalContractor', 'esusLoginName#renewalContractorMip'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            }
        },
        {
            name: '法务合同编号',
            value: 'mipContractCode',
            isFixed: true,
            type: 'input',
            span: 3
        },
        {
            name: '业务类型',
            value: 'businessType',
            optionNum: 'SYS_BMS_BUSI_TYPE',
            type: 'select',
            isFixed: true,
            span: 3
        },
        {
            name: '合同状态',
            value: 'contractStatus',
            isFixed: true,
            // optionNum: 'SYS_CR_CONTRACT_STATUS',
            type: 'select',
            options: [
                { value: '2', label: '有效' },
                { value: '4', label: '终止' },
                { value: '5', label: '失效' }
            ],
            span: 3
        },
        // {
        //     name: '客户名称',
        //     value: 'customerName',
        //     isFixed: true,
        //     type: 'input',
        //     span: 3
        // },
        {
            name: '客户名称',
            value: 'customerName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { prop: 'nameCn', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' }
            ],
            tableConfig: [
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'nameCn', value: '客户名称' }
            ],
            prefix: '/api-dc',
            advanceUrl: '/customers',
            cbParams: ['customerCode', 'nameCn#customerName'],
            advanceCode: 'advanceCode',
            beforeRequest: (params) => {
                params.customerStatus = '1';
                return params;
            }
        },
        {
            name: '合同生效日期',
            value: 'validTime',
            type: 'time',
            time: true,
            startPlaceholder: '生效日期从',
            endPlaceholder: '生效日期到',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            // isFixed: true,
            span: 6
        },
        {
            name: '合同性质',
            value: 'contractCharacter',
            optionNum: 'SYS_WM_CONTRACT_CHARACTER',
            type: 'select',
            span: 3,
            isFixed: true
        },
        {
            name: '客户系',
            value: 'customerGroupName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { prop: 'customerGroupName', value: '客户系名称' }
            ],
            tableConfig: [
                { prop: 'customerGroupName', value: '客户系名称' },
                { prop: 'calCustomerLevelCode', value: '客户等级', type: 'select', optionsKey: 'NEOCRM_CUSTOMER_LEVEL' },
                { prop: 'customerGroupCode', value: '行业类型', type: 'select', optionsKey: 'SYS_INDUSTRY_TYPE' },
                { prop: 'industryCategory', value: '行业大类', type: 'select', optionsKey: 'SRM_SUPPLIER_INDUSTRY' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: '/customer/group/info/page',
            cbParams: ['customerGroupCode', 'customerGroupName'],
            advanceCode: 'advanceCode'
        },
        {
            name: '续签风险',
            value: 'renewalRisk',
            optionNum: 'CRM_CONTRACT_RENEWAL_RISK',
            type: 'select',
            span: 3,
            isFixed: true
        }
    ],
    selection: true,
    index: false,
    contractAdvanceParams: {
        advanceLabel: '新建续签登记记录',
        dialogWidth: '1100px',
        advanceConfig: [
            { 'prop': 'contractCode', 'value': '单据编号' },
            {
                label: '分公司',
                prop: 'companyName',
                type: 'advance',
                name: '分公司',
                advanceConfig: [
                    { 'prop': 'companyNameCn', 'value': '分公司名称' },
                    { 'prop': 'companyCode', 'value': '分公司编码' }
                ],
                tableConfig: [
                    { 'prop': 'companyCode', 'value': '分公司编码' },
                    { 'prop': 'companyNameCn', 'value': '分公司名称' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/esCompany/popCompany`,
                cbParams: ['companyCode', 'companyNameCn#companyName'],
                mustFill: false,
                width: 180
            },
            {
                'prop': 'failTime',
                'value': '失效日期',
                'type': 'date',
                'dateType': 'daterange',
                'format': 'yyyy-MM-dd',
                'valueFormat': 'yyyy-MM-dd',
                'startPlaceholder': '失效开始从',
                'endPlaceholder': '失效结束时到',
                defaultValue: [halfYearLater(), '2099-12-31']
            }
        ],
        beforeRequest: (params) => {
            const startFailTime = params.failTime?.[0];
            const endFailTime = params.failTime?.[1];
            // if (startFailTime) {
            //     let endTime = new Date(endFailTime);
            //     endTime = endTime.setDate(endTime.getDate() + 1); // 时间加一天
            //     endTime = utils.formatDate(endTime);
            //     endFailTime = endTime;
            // };
            params.startFailTime = startFailTime || '';
            params.endFailTime = endFailTime || '';
            Object.keys(params).forEach(key => { // 把空字符串改成null
                if (params[key] === '') {
                    params[key] = null;
                }
            });
            Reflect.deleteProperty(params, 'failTime');
            return params;
        },
        tableConfig: [
            { 'prop': 'contractCode', 'value': '单据编号', width: '130px' },
            { 'prop': 'companyName', 'value': '分公司', width: '110px' },
            { 'prop': 'customerNames', 'value': '客户名称' },
            { 'prop': 'customerCodes', 'value': '客户编码' },
            { 'prop': 'contractStatus', 'value': '合同状态', type: 'select', optionsKey: 'SYS_CR_CONTRACT_STATUS' },
            { 'prop': 'validTime', 'value': '生效日期' },
            { 'prop': 'failTime', 'value': '失效日期' }
        ],
        multipleFilterItem: 'contractCode',
        newMultiple: true, // 多选
        prefix: 'api-lcrm',
        method: 'post',
        searchType: 'input',
        advanceUrl: '/contract/renewal/registration/queryContractAndCompany',
        cbParams: ['companyCode#companyCode', 'contractCode#contractCode', 'companyName'],
        hideTooltip: true
    },
    columns: [
        {
            label: '单据编号',
            prop: 'contractCode',
            width: 140
        },
        {
            label: '法务合同编号',
            prop: 'mipContractCode',
            width: 140,
            sort: true
        },
        {
            label: '片区',
            prop: 'region',
            width: 65
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 100
        },
        {
            label: '分公司编码',
            prop: 'companyCode',
            width: 100
        },
        {
            label: '服务平台',
            prop: 'siteName',
            width: 140
        },
        {
            label: '客户编码',
            prop: 'customerCode',
            width: 140,
            sort: true
        },
        {
            label: '客户名称',
            prop: 'customerName',
            width: 140,
            sort: true,
            fixed: 'left'
        },
        {
            label: '客户等级(计算)',
            prop: 'calCustomerLevel',
            // type: 'select',
            optionsKey: 'NEOCRM_CUSTOMER_LEVEL',
            // options: {
            //     '2': '普通',
            //     '4': 'TOP'
            // },
            type: 'slot',
            width: 85
        },
        {
            label: '行业大类',
            prop: 'industryCategories',
            width: 140
        },
        {
            label: '行业类型',
            prop: 'industryType',
            width: 140
        },
        // {
        //     label: '客户系编码',
        //     prop: 'customerGroupCode',
        //     width: 140
        // },
        {
            label: '客户系名称',
            prop: 'customerGroupName',
            width: 140
        },
        {
            label: '业务类型',
            prop: 'businessType',
            optionsKey: 'SYS_BMS_BUSI_TYPE',
            type: 'slot',
            width: 180
        },
        {
            label: '商机号',
            prop: 'opportunityCode',
            width: 140
        },
        {
            label: '业务结构（仓库类型）',
            prop: 'warehouseType',
            type: 'slot',
            // type: 'select',
            optionsKey: 'NEOCRM_WH_TYPE',
            width: 150
        },
        {
            label: '生效日期',
            prop: 'validTime',
            width: 105,
            sort: true
        },
        {
            label: '失效日期',
            prop: 'failTime',
            width: 105,
            sort: true
        },
        {
            label: '合同状态',
            prop: 'contractStatus',
            type: 'select',
            optionsKey: 'SYS_CR_CONTRACT_STATUS',
            width: 100,
            sort: true
        },
        {
            label: '合同性质',
            prop: 'contractCharacter',
            type: 'select',
            optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
            width: 100,
            sort: true
        },
        {
            label: '续签状态',
            prop: 'renewalStatus',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_RENEWAL_STATUS',
            width: 100
        },
        {
            label: '续签风险',
            prop: 'renewalRisk',
            type: 'slot',
            optionsKey: 'CRM_CONTRACT_RENEWAL_RISK',
            width: 100
        },
        {
            label: '风险原因',
            prop: 'riskCause',
            // type: 'select',
            type: 'slot',
            optionsKey: 'CRM_CONTRACT_RENEWAL_RISK_CAUSE',
            width: 160
        },
        {
            label: '终止原因',
            prop: 'terminationCause',
            width: 140
        },
        {
            label: '项目进度',
            prop: 'projectSchedule',
            width: 140
        },
        // {
        //     label: '续签进度',
        //     prop: 'renewalSchedule',
        //     type: 'select',
        //     optionsKey: 'NEOCRM_RESP_TYPE',
        //     width: 140
        // },
        {
            label: '上游涨降',
            prop: 'upstreamRenewal',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_RENEWAL_UPSTREAM',
            width: 160
        },
        {
            label: '上游涨降幅度(%)',
            prop: 'upstreamAmplitude',
            width: 150
        },
        {
            label: '下游涨降',
            prop: 'downstreamRenewal',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_RENEWAL_DOWNSTREAM',
            width: 140
        },
        {
            label: '下游涨降幅度(%)',
            prop: 'downstreamAmplitude',
            width: 150
        },
        {
            label: '外部业务退出报告流程链接',
            prop: 'processLink',
            width: 170
        },
        {
            label: '提前终止时间',
            prop: 'earlyTerminationTime',
            width: 160
        },
        {
            label: '提前续签/延期时间',
            prop: 'advanceOrDelayTime',
            width: 160
        },
        {
            label: '附件',
            prop: 'file',
            type: 'slot',
            width: 80
        },
        {
            label: '主动/被动退出',
            prop: 'exitStatus',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_EXIT_STATUS',
            width: 110
        },
        {
            label: '终止原因大类',
            prop: 'causeCategories',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_CAUSE_CATEGORIES',
            width: 170
        },
        {
            label: '终止原因小类',
            prop: 'causeSubcategory',
            type: 'select',
            optionsKey: 'CRM_CONTRACT_CAUSE_SUBCATEGORY',
            width: 170
        },
        {
            label: '续签新合同号',
            prop: 'renewalContractCode',
            width: 100
        },
        {
            label: '续签生效日期',
            prop: 'renewalValidTime',
            width: 100
        },
        {
            label: '续签失效日期',
            prop: 'renewalFailTime',
            width: 100
        },
        {
            label: '经营中心利润率(%)',
            prop: 'operatGrossMarginPlan',
            width: 140
        },
        // {
        //     label: '实际经营中心利润率(%)',
        //     prop: 'profitMargin',
        //     type: 'input',
        //     width: 200
        // },
        // {
        //     label: '实际毛利偏差',
        //     prop: 'grossProfitDeviation',
        //     type: 'input',
        //     width: 180
        // },
        // {
        //     label: '亏损额',
        //     prop: 'lossAmount',
        //     type: 'input',
        //     width: 140
        // },
        {
            label: '分公司营销经理',
            prop: 'marketingManager',
            width: 140
        },
        {
            label: '分公司续签对接人',
            prop: 'renewalManager',
            width: 140
        },
        {
            label: '续签承接人',
            prop: 'renewalContractor',
            width: 150
        },
        {
            label: '商机开发人',
            prop: 'ownerName',
            width: 150
        },
        {
            label: '商机开发人Mip',
            prop: 'ownerCode',
            width: 150
        },
        {
            label: '备注',
            prop: 'remark',
            width: 140
        },
        {
            label: '关联主合同编号',
            prop: 'mainContract',
            width: 120
        },
        {
            label: '创建人',
            prop: 'createUserName'
        },
        {
            label: '创建时间',
            prop: 'createTime',
            sortable: true,
            width: 154
        },
        {
            label: '修改人',
            prop: 'updateUserName'
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            sortable: true,
            width: 154
        },
        {
            label: '变更续签承接人记录',
            prop: 'changeRecord',
            width: 140
        },
        {
            label: '变更续签承接人时间',
            prop: 'changeRecordTime',
            width: 140
        },
        {
            label: '待办处理人',
            prop: 'scheduleHandlePerson',
            width: 90
        },
        {
            label: '待办处理时间',
            prop: 'scheduleHandlePersonTime',
            width: 140
        }
    ],
    actions: { // 表格操作列的配置
        fixedWidth: 80, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: [
            {
                label: '编辑',
                event: 'edit',
                displayRule: (row) => {
                    return true;
                }
            }
        ]
    },
    importConfig: {
        action: '',
        multiple: false,
        accept: 'xlsx',
        showFileList: false,
        fileType: '只能上传Excel文件',
        manualUpload: true
    }
};
