<template>
    <div class="contract-registration-detail-page" :v-loading="detailLoading">
        <div class="detail-toolBar">
            <lots-button
                    v-has="authorityCode.save"
                    type="primary"
                    @click="saveHeader"
                    :loading="saveLoading">保 存
            </lots-button>
        </div>
        <!-- 表单区域 -->
        <collapse-infos
            ref="upholdHealder"
            :detailData="detailDataLocal"
            :collapse="COLLAPSE"
            :activeCollapses="activeCollapses"
            @change="handleInfosChange">
            <!-- 表单字段插槽 -->
            <template v-slot:collapse-append="{form, itemSetting, rIndex, collapseItem, dictData}">
                <span v-if="itemSetting.prop === 'file'">
                    <lots-upload-button :text="'上传附件'" :bucket="'annto-lcrm'" :disabled="readonly"
                        @callback="(data) => { upLoadData(form, itemSetting, data); }">
                    </lots-upload-button>
                    <div class="file-infos">
                        <template v-if="form[itemSetting.prop]
                            && form[itemSetting.prop].length">
                            <div
                                class="file-list"
                                v-for="(item, index) in form[itemSetting.prop]"
                                :key="index + item.fileName">
                                <div
                                    class="file-name upload"
                                    :title="item.fileName">
                                    {{`${index+1}. `}}
                                    <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
                                </div>
                                <div class="delete-btn">
                                    <el-button
                                        type="text"
                                        plain>
                                        <a :href="item.fileUrl" target="_blank">下载</a>
                                    </el-button>
                                    <el-button
                                        type="text"
                                        :disabled="readonly"
                                        plain
                                        @click="handleDeleteFile(form, itemSetting, index)">删除</el-button>
                                </div>
                            </div>
                        </template>
                    </div>
                </span>
                <span v-else-if="!itemSetting.optionsKey" class="custom-span" :title="form[itemSetting.prop]">
                    {{(form[itemSetting.prop] || form[itemSetting.prop] === 0) ? form[itemSetting.prop] : '--'}}
                </span>
            </template>
        </collapse-infos>
    </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import lotsUploadButton from '@/components/lots/oss/UploadBtn';
import collapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import { collapse, upstreamAmplitudeRule, downstreamAmplitudeRule } from './collapseInfosConfig.js';
import { registrationGetByIdApi, contractRenewalRegistrationSave } from '@mdm/api/contractManage/contractRegistration.js';
import { Message } from 'element-ui';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import _ from 'lodash';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import dayjs from 'dayjs';
export default {
    name: 'detailPage',
    components: { lotsButton, lotsUploadButton, collapseInfos },
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const config = _.cloneDeep(collapse);
        const activeCollapses = ref(['basicInfo', 'importantInfo']);
        const authorityCode = reactive(btnAuthority.contractRegistration);
        const detailDataLocal = ref({});
        const readonly = ref(false);
        const currentMonthSart = dayjs().startOf('month').valueOf(); // 获取当前时间的月份第一天时间戳
        // eslint-disable-next-line max-lines-per-function
        const COLLAPSE = computed(() => { // 配置项动态计算
            const { renewalStatus, renewalRisk, renewalContractorMip, exitStatus, causeCategories, earlyTerminationTime, upstreamRenewal, downstreamRenewal } = detailDataLocal.value;
            const isRisk = ['1', '2'].includes(renewalRisk + ''); // 中高风险
            const noMip = !renewalContractorMip; // 续签承接人为空
            // const causeSubcategoryFlag = ['8', '9'].includes(causeSubcategory + ''); // 终止原因小类包含：8到期涨价业务丢失 9投标竞价失败"
            const status = renewalStatus + '';
            // 提前终止时间小于本月
            const isBefore = earlyTerminationTime && (dayjs(earlyTerminationTime).valueOf() < currentMonthSart);
            // eslint-disable-next-line max-lines-per-function
            config.forEach((v, i) => {
                // eslint-disable-next-line complexity, max-lines-per-function
                v.formList.forEach((v2, i2) => {
                    if (v2.prop === 'renewalStatus') { // 续签状态：1已洽谈 2已续签 3已终止
                        v2.rule[0].required = noMip;
                    }
                    if (v2.prop === 'terminationCause') { // 终止原因
                        v2.disabled = ['1', '2'].includes(status);
                        v2.rule[0].required = status === '3';
                    }
                    if (v2.prop === 'exitStatus') { // 主动/被动退出
                        v2.disabled = ['1', '2'].includes(status);
                        v2.rule[0].required = status === '3';
                    }
                    if (v2.prop === 'causeCategories') { // 终止原因大类
                        v2.disabled = ['1', '2'].includes(status);
                        v2.rule[0].required = status === '3';
                        if (exitStatus) {
                            const newOptions = {};
                            // eslint-disable-next-line max-nested-callbacks
                            dictData.value['CAUSE_CATEGORIES_TO_EXIT_STATUS'] && Object.keys(dictData.value['CAUSE_CATEGORIES_TO_EXIT_STATUS']).forEach(key => {
                                if (dictData.value['CAUSE_CATEGORIES_TO_EXIT_STATUS'][key] === exitStatus) {
                                    newOptions[key] = dictData.value['CRM_CONTRACT_CAUSE_CATEGORIES'][key];
                                }
                            });
                            v2.options = newOptions;
                        } else {
                            v2.options = dictData.value['CRM_CONTRACT_CAUSE_CATEGORIES'];
                        }
                    }
                    if (v2.prop === 'causeSubcategory') { // 终止原因小类
                        v2.disabled = ['1', '2'].includes(status);
                        v2.rule[0].required = status === '3';
                        if (causeCategories) {
                            const newOptions = {};
                            // eslint-disable-next-line max-nested-callbacks
                            dictData.value['CAUSE_SUBCATEGORY_TO_CATEGORIES'] && Object.keys(dictData.value['CAUSE_SUBCATEGORY_TO_CATEGORIES']).forEach(key => {
                                if (dictData.value['CAUSE_SUBCATEGORY_TO_CATEGORIES'][key] === causeCategories) {
                                    newOptions[key] = dictData.value['CRM_CONTRACT_CAUSE_SUBCATEGORY'][key];
                                }
                            });
                            v2.options = newOptions;
                        } else {
                            v2.options = dictData.value['CRM_CONTRACT_CAUSE_SUBCATEGORY'];
                        }
                    }
                    if (v2.prop === 'processLink') {
                        v2.disabled = ['1', '2'].includes(status);
                        v2.rule[0].required = isBefore;
                    }
                    if (v2.prop === 'earlyTerminationTime') {
                        v2.disabled = ['1', '2'].includes(status);
                        v2.rule[0].required = status === '3';
                    }
                    if (v2.prop === 'renewalRisk') {
                        v2.disabled = ['2', '3'].includes(status);
                        v2.rule[0].required = status === '1';
                    }
                    if (v2.prop === 'riskCause') {
                        v2.disabled = ['2', '3'].includes(status);
                        v2.rule[0].required = ['2', '3'].includes(status) ? false : isRisk;
                    }
                    if (v2.prop === 'projectSchedule') {
                        v2.rule[0].required = status === '1';
                    }
                    if (v2.prop === 'advanceOrDelayTime') {
                        v2.disabled = ['3'].includes(status);
                    }

                    if (['upstreamAmplitude'].includes(v2.prop)) {
                        v2.rule[0].required = upstreamRenewal ? upstreamAmplitudeRule[+upstreamRenewal].required : false;
                        v2.rule[1].pattern = upstreamAmplitudeRule[+upstreamRenewal]?.pattern || '';
                        v2.rule[1].message = upstreamAmplitudeRule[+upstreamRenewal]?.message || '';
                    }
                    if (['downstreamAmplitude'].includes(v2.prop)) {
                        v2.rule[0].required = downstreamRenewal ? downstreamAmplitudeRule[+downstreamRenewal].required : false;
                        v2.rule[1].pattern = downstreamAmplitudeRule[+downstreamRenewal]?.pattern || '';
                        v2.rule[1].message = downstreamAmplitudeRule[+downstreamRenewal]?.message || '';
                    }
                    v2.placeholder = v2.disabled ? null : (['select', 'time'].includes(v2.type) ? '请选择' : '请输入');
                });
            });
            return config;
        });
        const dictData = ref({});
        const detailLoading = ref(false);
        onMounted(async() => {
            // eslint-disable-next-line max-len
            const res = await getDictDataApi({ dictCodes: 'CRM_CONTRACT_CAUSE_CATEGORIES,CRM_CONTRACT_CAUSE_SUBCATEGORY,CAUSE_CATEGORIES_TO_EXIT_STATUS,CAUSE_SUBCATEGORY_TO_CATEGORIES' });
            dictData.value = res.data;
            fetchData();
        });
        const fetchData = () => {
            const { id } = props.detailData;
            detailLoading.value = true;
            registrationGetByIdApi({ id }).then(res => {
                if (+res.code === 0 && res.data) {
                    handleInfosChange({ form: formatData(res.data) });
                }
            }).finally(() => { detailLoading.value = false; });
        };
        // 处理详情展示数据格式
        const formatData = (data) => {
            const newData = _.cloneDeep(data);
            !newData.file && (newData.file = []);
            const multipleKeys = ['customerLevel', 'businessType', 'warehouseType', 'riskCause'];
            multipleKeys.forEach(key => {
                if (Array.isArray(data[key])) {
                    newData[key] = data[key];
                } else if (data[key] && data[key].length) {
                    newData[key] = data[key].split(',');
                } else if (!data[key]) {
                    newData[key] = [];
                }
            });
            return newData;
        };
        const isHandleEdit = ref(false);
        // eslint-disable-next-line max-lines-per-function, complexity
        const handleInfosChange = (formObjData = {}) => { // 表单组件编辑回调，更新本地数据
            const { itemSetting, targetForm, refName } = formObjData;
            // 监控表单是否编辑过
            isHandleEdit.value = Boolean(itemSetting);
            if (!itemSetting) { // 页面初始化、刷新时走：
                detailDataLocal.value = Object.assign({}, detailDataLocal.value, formObjData.form);
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.clearValidate(); // 重置校验报错
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.refleshPage(detailDataLocal.value); // 刷新表单子组件
            } else { // collapse组件编辑回调时走：
                if (itemSetting.prop === 'exitStatus') { // 改变主动/被动退出，置空关联项
                    if (targetForm[itemSetting.prop]) {
                        targetForm['causeCategories'] = '';
                        targetForm['causeSubcategory'] = '';
                    }
                } else if (itemSetting.prop === 'causeCategories') { // 改变终止原因大类，置空关联项
                    if (targetForm[itemSetting.prop]) {
                        targetForm['causeSubcategory'] = '';
                    }
                } else if (itemSetting.prop === 'earlyTerminationTime') {
                    // 提前终止时间小于本月
                    const isBefore = targetForm[itemSetting.prop] && (dayjs(targetForm[itemSetting.prop]).valueOf() < currentMonthSart);
                    isBefore && Message.warning('“提前终止时间”小于本月时，“外部业务退出报告流程链接”字段必填');
                } else if (itemSetting.prop === 'advanceOrDelayTime') {
                    targetForm['earlyTerminationTime'] = '';
                }
                const { file } = detailDataLocal.value;
                // 除了插槽参数以外，其他参数取最新编辑的参数更新(因：插槽的编辑不会触发collapse组件change事件回调，因此change事件回调的插槽的参数是旧数据不能取)
                detailDataLocal.value = Object.assign(
                    {},
                    detailDataLocal.value,
                    formObjData.form,
                    targetForm,
                    { file } // 保证插槽数据用最新的
                );
                if (targetForm && refName) {
                    const targetColl = COLLAPSE.value.find(item => item.ref === refName); // 根据最新状态清空禁用的字段
                    if (targetColl && targetColl.formList) {
                        targetColl.formList.forEach(col => {
                            if (col.disabled) {
                                targetForm[col.prop] = null;
                                ['customerLevel', 'businessType', 'warehouseType', 'riskCause'].includes(col.prop) && (targetForm[col.prop] = []);
                                col.placeholder = null;
                            }
                        });
                        detailDataLocal.value = Object.assign(
                            {},
                            detailDataLocal.value,
                            targetForm,
                            { file } // 保证插槽数据用最新的
                        );
                        const timer = setTimeout(() => {
                            setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.clearValidate(); // 重置校验报错
                            clearTimeout(timer);
                        });
                    }
                }
            }
        };
        const upLoadData = async (form, itemSetting, data) => {
            const { prop } = itemSetting;
            form[prop] = form[prop] ? form[prop] : [];
            if (data && +data.code === 0) {
                form[prop].push({ fileName: data.data.name, fileUrl: data.data.downUrl });
                const formRefs = setupContext.refs.upholdHealder.$refs.dcForm;
                if (prop === 'file') {
                    if (itemSetting.rule[0].required) {
                        const targetRef = formRefs.find(item => item._props.refName === 'importantForm');
                        targetRef.$refs['importantForm'].validateField(prop);
                    }
                    detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
                    isHandleEdit.value = true;
                }
            }
        };
        const handleDeleteFile = (form, itemSetting, index) => {
            const { prop } = itemSetting;
            form[prop].splice(index, 1);
            detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
            const formRefs = setupContext.refs.upholdHealder.$refs.dcForm;
            if (prop === 'file' && itemSetting.rule[0].required) {
                const targetRef = formRefs.find(item => item._props.refName === 'importantForm');
                targetRef.$refs['importantForm'].validateField(prop);
            }
            isHandleEdit.value = true;
        };
        // 处理保存字段格式
        const saveDataFormat = (data) => {
            data.customerLevel && (data.customerLevel = data.customerLevel.join(','));
            data.businessType && (data.businessType = data.businessType.join(','));
            data.warehouseType && (data.warehouseType = data.warehouseType.join(','));
            data.riskCause && (data.riskCause = data.riskCause.join(','));
            return data;
        };
        const saveLoading = ref(false);
        const saveHeader = () => {
            const data = _.cloneDeep(setupContext.refs.upholdHealder.onsave());
            if (!data) return;
            const params = saveDataFormat(detailDataLocal.value);
            saveLoading.value = true;
            contractRenewalRegistrationSave([params]).then(res => {
                if (+res.code === 0) {
                    Message.success('保存成功');
                    fetchData();
                }
            }).finally(() => { saveLoading.value = false; });
        };
        watch(
            () => props.detailData,
            (newVal) => {
                fetchData();
            },
            { deep: true }
        );
        return {
            authorityCode,
            detailLoading,
            saveLoading,
            activeCollapses,
            detailDataLocal,
            COLLAPSE,
            handleInfosChange,
            upLoadData,
            handleDeleteFile,
            saveHeader,
            readonly
        };
    }
};
</script>

<style lang="less">
.contract-registration-detail-page {
    .file-infos {
        width: 100%;
        padding: 0 10px 0 0;
        .file-list {
            width: 100%;
            padding: 2px 0 2px 4px;
            display: flex;
            // justify-content: space-between;
            .file-name {
                // flex: 1;
                width: 500px;
                padding-right: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .view-btn, .delete-btn {
                width: 200px;
                display: flex;
                a {
                    text-decoration: none;
                    color: #4285F5;
                }
                .el-button {
                    margin-left: 10px;
                }
            }
        }
    }
    .collapse-infos {
        .el-collapse {
            .el-collapse-item:first-child {
                .el-form-item {
                    margin-bottom: 2px;
                }
            }
            .el-collapse-item__arrow {
                margin: 0 8px 0 5px;
            }
        }
    }
}
</style>
