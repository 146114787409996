<!-- eslint-disable max-lines -->
<template>
    <div class="contract-manage">
        <Paper class="contract-manage-paper">
            <el-tabs
                class="contract-list-tab-pane"
                v-model="activeTabs"
                @tab-remove="removeTab"
                @tab-click="switchTabs">
                <!-- 合同列表页 -->
                <el-tab-pane
                    label="续签登记列表"
                    name="theList"
                    ref="myPane"
                    v-loading="holePageLoading">
            <list-layout-paper>
                <template slot="header-search" class="searchBox">
                    <search-box
                        ref="searchBox"
                        :search-key="config.name"
                        :fields="config.searchFields"
                        :showResetBtn="true"
                        @search-change="accountList.searchList"
                        @elementChange="handleFielChange"
                    />
                </template>
                <template slot="header-button">
                    <lotsButton
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        size="mini"
                        @click="handleAddRow"
                        >新增</lotsButton>
                  <lotsButton
                        v-has="authorityCode.import"
                        icon="el-icon-delete"
                        type="primary"
                        size="mini"
                        title="导入"
                        @click="handleDeleteRows">删除
                    </lotsButton>
                    <lotsButton
                        v-has="authorityCode.import"
                        icon="el-icon-upload2"
                        type="primary"
                        size="mini"
                        title="导入"
                        @click="openImport">导入
                    </lotsButton>
                    <report-export
                        #default="{ submit }"
                        reportCode="REPORT_CRM_CONTRACT_RENEWAL">
                            <lotsButton
                                v-has="authorityCode.export"
                                icon="el-icon-download"
                                type="primary"
                                size="mini"
                                @click="handleExport(submit, 'split')">导出
                            </lotsButton>
                    </report-export>
                    <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                        <el-button
                            icon="iconfont icon-list_install"
                            type="primary"
                            size="mini"
                            :title="'列配置'"
                            @click="showColumnConfigDialog"
                            plain />
                    </el-tooltip>
                </template>
                <!-- 表格 -->
                <template v-slot:list="{ tableHeight }">
                    <table-box
                        ref="clientOrder"
                        :height="tableHeight"
                        v-loading="accountList.tableLoading"
                        :selection="config.selection"
                        :index="config.index"
                        :columns="config.columns"
                        :rows="accountList.totalData"
                        :actions="config.actions"
                        @action-click="actionClick"
                        @selection-change="accountList.handleSelectedRow"
                        @sort-change="handleSort"
                        @row-dblclick="rowDBclick">
                        <template #col-header="{ col }">
                            <span v-if="col.prop === 'renewalRisk'">
                                {{ col.label }}
                                <el-tooltip effect="dark" placement="top">
                                    <i class="el-icon-question"></i>
                                    <template slot="content">
                                        中高风险需要同步营销中心，拉群同步项目推动计划，协调各方解决此风险项目处理<br/>
                                    </template>
                                </el-tooltip>
                            </span>
                            <span v-else-if="col.prop === 'riskCause'">
                                {{ col.label }}
                                <el-tooltip effect="dark" placement="top">
                                    <i class="el-icon-question"></i>
                                    <template slot="content">
                                        风险原因超过2项时，建议标识为高风险<br/>
                                    </template>
                                </el-tooltip>
                            </span>
                            <span v-else-if="col.prop === 'renewalContractor'">
                                {{ col.label }}
                                <el-tooltip effect="dark" placement="top">
                                    <i class="el-icon-question"></i>
                                    <template slot="content">
                                        续签承接人每月5号/20号会收到待办<br/>
                                    </template>
                                </el-tooltip>
                            </span>
                            <span v-else>{{ col.label }}</span>
                        </template>
                        <template #col-append="{col, row, rIndex}">
                            <span v-if="col.prop === 'calCustomerLevel'">{{ codesToNames(row[col.prop], dictData[col.optionsKey]) }}</span>
                            <span v-else-if="col.prop === 'businessType'">{{ codesToNames(row[col.prop], dictData[col.optionsKey]) }}</span>
                            <span v-else-if="col.prop === 'warehouseType'">{{ codesToNames(row[col.prop], dictData[col.optionsKey]) }}</span>
                            <span v-else-if="col.prop === 'renewalRisk'" :class="[['1'].includes(row[col.prop] + '') ? 'red-color' : '']">
                                {{ codesToNames(row[col.prop], dictData[col.optionsKey]) }}
                            </span>
                            <span v-else-if="col.prop === 'riskCause'">{{ codesToNames(row[col.prop], dictData[col.optionsKey]) }}</span>
                            <lots-button v-else-if="col.prop === 'file'" type="text"  @click.stop="handleOpen(row)">查看附件</lots-button>
                        </template>
                    </table-box>
                </template>
                <template slot="footer">
                    <lots-pagination
                        @size-change="accountList.sizeChange"
                        :current-page.sync="accountList.pageNo"
                        @current-change="accountList.pageChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="accountList.pageTotal" >
                    </lots-pagination>
                </template>
            </list-layout-paper>
                </el-tab-pane>
                <!-- 详情页签 -->
                <el-tab-pane
                    closable
                    v-for="item in tabList"
                    :key="item.code + item.detailPageState"
                    :label="item.contractMark"
                    :name="item.code + item.detailPageState">
                    <detail-page
                        :ref="`datailTabs${item.code + item.detailPageState}`"
                        :code="item.code"
                        :detailPageState="item.detailPageState"
                        :disableBus="item.buttonData"
                        :detailData="item.detailData">
                    </detail-page>
                </el-tab-pane>
            </el-tabs>
            <add-file-dialog
                v-if="addFileVisible"
                :fileReadonly="fileReadonly"
                :row="theRow"
                @confirm="dialogConfirm"
                @handleClose="addFileVisible = false">
            </add-file-dialog>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.columns"
                ref="otpColumnConfig"
                @header-change="config.columns = $event"
            />
            <!-- 导入弹窗 -->
            <el-dialog
                @close="closeLog"
                v-loading="importLoading"
                title="导入数据"
                :visible.sync="addDialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                width="600px">
                <el-form
                    :model="filesForm"
                    label-position="right"
                    class="form-dialog"
                    label-width="80px"
                    ref="filesFormDialog">
                    <div class="upload-btn">
                        <lots-button size="mini" type="primary" @click="downLoad"
                            :loading="downloadLoading">下载模板</lots-button>
                        <el-upload
                            class="upload-demo"
                            ref="upload"
                            :on-error="handleError"
                            :action="config.importConfig.action"
                            :data="{ inOutType: 'OUT' }"
                            :auto-upload="!config.importConfig.manualUpload"
                            :multiple="config.importConfig.multiple"
                            :show-file-list="config.importConfig.showFileList"
                            :accept="config.importConfig.accept"
                            :on-change="onChange"
                            :limit="2"
                            :with-credentials="true"
                            :file-list="fileList">
                            <lots-button size="mini" type="primary">上传附件</lots-button>
                        </el-upload>
                    </div>
                    <el-form-item label="附件名称：" prop="accessoryName">
                        <span>{{ filesForm.accessoryName || '--' }}</span>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <lots-button size="mini" @click="closeLog">取 消</lots-button>
                <lots-button size="mini" type="primary" @click="handleImport" :loading="importLoading">导入</lots-button>
                </span>
            </el-dialog>
            <!-- 添加续签合同 -->
        <advance-multi-dialog
            ref="advanceMultiDialog"
            @confirm="contractSaveSure">
        </advance-multi-dialog>
        </Paper>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
// import editTableBox from '@/components/lots/editTableBox/Index';
import tableBox from '@/components/lots/tableBox/Index';
import {
    contractRenewalRegistrationPage,
    registrationImportApi,
    renewalFileDownloadApi,
    contractRenewalRegistrationDel,
    contractRenewalRegistrationInsert
} from '@mdm/api/contractManage/contractRegistration.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted, onActivated, computed } from '@vue/composition-api';
import { Message } from 'element-ui';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import reportExport from '@/components/lots/reportExportPlugin';
import columnConfig from '@/components/lots/columnConfig/Index';
import store from '@/store';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import _ from 'lodash';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import addFileDialog from '@/modules/mdm/views/contractModules/components/addFileDialog/Index';
import detailPage from './detail/Detail.vue';
import advanceMultiDialog from '@/components/lots/otpAdvanceMultiDialog';
const { MAGIC_NUMBER } = utils;
const EDIT_TYPE = {
    EDIT: 2,
    CANCEL: -3
};
export default {
    name: 'contractRegistration',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        // editTableBox,
        lotsPagination,
        lotsButton,
        reportExport,
        addFileDialog,
        columnConfig,
        detailPage,
        advanceMultiDialog
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(configStatic);
        const searchObj = reactive({});
        const authorityCode = reactive(btnAuthority.contractRegistration);
        const { user } = store.getters;
        class AccountListClass extends AdvanceListClass {
            beforeGetList(condition) {
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        v.edit = -3;
                        v.allowEdit = true;
                        v.contractStatus = (v.contractStatus || v.contractStatus === 0) ? v.contractStatus + '' : '';
                        v.contractCharacter = (v.contractCharacter || v.contractCharacter === 0) ? v.contractCharacter + '' : '';
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const accountList = new AccountListClass({
            Api: {
                listApi: contractRenewalRegistrationPage, // 查询接口
                deleteApi: contractRenewalRegistrationDel
            }
        });
        const handleFielChange = _.debounce(({ column, searchData }) => {
            if (searchData.contractCodes) {
                const orderNoS = searchData.contractCodes.split(/,|，|;|；|\r|\n|\r\n|\s+/);
                searchData.contractCodes = orderNoS.join(';');
            }
        }, MAGIC_NUMBER.TWO_HUNDRED);
        const searchDataFormat = (condition = {}, params = {}) => {
            if (condition.effectTime && condition.effectTime.length) {
                condition.effectStartTime = utils.formatDateTime(condition.effectTime[0]);
                condition.effectEndTime = utils.formatDateTime(condition.effectTime[1]);
                Reflect.deleteProperty(condition, 'effectTime');
            }
            if (condition.validTime && condition.validTime.length) {
                condition.startValidTime = utils.formatDateTime(condition.validTime[0]);
                condition.endValidTime = utils.formatDateTime(condition.validTime[1]);
                Reflect.deleteProperty(condition, 'validTime');
            }
            if (condition.failTime && condition.failTime.length) {
                condition.startFailTime = utils.formatDateTime(condition.failTime[0]);
                condition.endFailTime = utils.formatDateTime(condition.failTime[1]);
                Reflect.deleteProperty(condition, 'failTime');
            }
            if (Array.isArray(condition.calCustomerLevelCodes)) {
                const codeSortBy = _.sortBy(condition.calCustomerLevelCodes);
                condition.calCustomerLevelCodeStr = codeSortBy.join(',');
            }
            if (condition.customerGroupCode) {
                condition.customerGroupCodeList = [condition.customerGroupCode];
                Reflect.deleteProperty(condition, 'customerGroupCode');
                Reflect.deleteProperty(condition, 'customerGroupName');
            }
            condition.orderBy = orderBy.value;
            condition.orderByType = orderByType.value;
            condition.userCode = user.userCode;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return { ...condition, ...params };
        };
        const tabList = ref([]); // 打开的详情tab页集
        const activeTabs = ref('theList');
        const removeTab = (targetName) => { // 删除详情标签
            if (activeTabs.value === targetName) {
                activeTabs.value = 'theList';
            }
            tabList.value = tabList.value.filter(
                (tab) => (tab.code + tab.detailPageState) !== targetName
            );
        };
        const switchTabs = (data) => {
            if (data.name === 'theList') {
                accountList.getList(setupContext.refs.searchBox.searchData);
            }
        };
        const currDetailData = ref({});
        // 双击表格
        const rowDBclick = (row) => {
            // 滚动到顶部
            const element = document.getElementsByClassName('app-main');
            element[0].scrollTo(0, 0);
            currDetailData.value = _.cloneDeep(row); // 深拷贝是防止表格源数据被修改
            const status = 'main';
            const name = '续签登记';
            const { contractCode, companyCode } = row;
            const code = contractCode + companyCode; // 合同编码+分公司编码 （唯一）
            newDetailPage(code, currDetailData.value, name, status);
        };
        const newDetailPage = (code, data, name, state) => {
            data.contractStatus = data['contractStatus'] ? data['contractStatus'] : 0;
            const judge = tabList.value.filter((item) => (item.code + item.detailPageState) === code + state);
            if (judge.length === 0 && tabList.value.length === 8) {
                Message.warning('已超出8个详情页，请关闭后重新打开');
            } else {
                const dataGather = {
                    contractMark: name + code.substring(code.length - 4),
                    code,
                    detailData: _.cloneDeep(data),
                    detailPageState: state
                };
                if (judge.length === 0) {
                    // 第一次打开
                    tabList.value.push(dataGather);
                }
                activeTabs.value = code + state; // tabs切换到对应详情页
            }
        };
        const holePageLoading = ref(false);
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const addFileVisible = ref(false);
        const fileReadonly = ref(false);
        const theRow = ref({});
        const handleOpen = (row) => {
            if (row.edit === EDIT_TYPE.CANCEL) {
                fileReadonly.value = true;
            } else {
                fileReadonly.value = false;
            }
            theRow.value = row;
            addFileVisible.value = true;
        };
        const dialogConfirm = (data) => {
            theRow.value.edit !== EDIT_TYPE.CANCEL && (theRow.value.file = data);
            addFileVisible.value = false;
        };
        const actionClick = (event, row, index) => {
            if (event === 'edit') {
                rowDBclick(row);
            }
        };
        const handleExport = async(submit, type) => {
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const { value } = accountList.searchModel;
            const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
            const params = {
                ...searchDataFormat(newData),
                deleteFlag: 0
            };
            Object.keys(params).forEach(v => {
                if (params[v] === undefined || params[v] === null || params[v] === '') {
                    delete params[v];
                }
            });
            submit(params);
        };
        const rendertable = ref(true);
        const orderBy = ref('fail_time');
        const orderByType = ref('desc');
        // 排序
        const handleSort = ({ prop, order }) => {
            orderBy.value = prop.replace(/([A-Z])/g, '_$1').toLowerCase();
            orderByType.value = order === 'ascending' ? 'asc' : 'desc';
            accountList.getList();
        };
        const codesToNames = computed(() => {
            function codeToName (codes, dictObj) {
                const names = [];
                const codesArr = codes ? codes.split(',') : [];
                if (codesArr && codesArr.length) {
                    codesArr.forEach((key) => {
                        if (dictObj && dictObj[key]) {
                            names.push(dictObj[key]); // dictData.value['SYS_BMS_BUSI_TYPE']
                        }
                    });
                }
                return names.join(',');
            };
            return codeToName;
        });
        const openImport = () => {
            addDialog.value = true;
        };
        const fileList = ref([]);
        const filesForm = ref({});
        const addDialog = ref(false);
        const downloadLoading = ref(false);
        // 下载模板
        const downLoad = (data) => {
            downloadLoading.value = true;
            renewalFileDownloadApi({ fileName: 'LCRM续签合同登记导入模板.xlsx' }).then(res => {
                if (+res.code === 0) {
                    Message.success('下载成功');
                }
            }).finally(() => { downloadLoading.value = false; });
        };
        const closeLog = () => {
            fileList.value = [];
            filesForm.value.accessoryName = '';
            addDialog.value = false;
        };
        // 上传失败
        const handleError = () => {
            Message.error('上传失败');
        };
        const onChange = (file) => {
            fileList.value = [];
            filesForm.value.accessoryName = file.name;
            fileList.value.push(file);
        };
        const importLoading = ref(false);
        // 导入
        const handleImport = async() => {
            if (!fileList.value.length) {
                return Message.warning('请先上传附件');
            }
            const formData = new FormData();
            // 把接口需要的参数带进去
            formData.append('multipartFile', fileList.value[0].raw);
            let res = null;
            importLoading.value = true;
            try {
                res = await registrationImportApi(formData);
                importLoading.value = false;
            } catch (error) {
                importLoading.value = false;
            }
            if (+res.code === 0) {
                Message.success('导入成功');
                accountList.getList();
                closeLog();
            } else {
                // 报错清空文件
                fileList.value = [];
                filesForm.value.accessoryName = '';
            }
        };
        const handleDeleteRows = async () => { // 调批量删除接口
            if (!accountList.selectedRows.value.length) {
                return Message.warning('请勾选表格数据再操作！');
            }
            const ids = JSON.parse(JSON.stringify(accountList.selectedRows.value)).map((v) => v.id);
            accountList.deleteRow({ ids }, { msg: '是否确认要删除？' });
        };
        const handleAddRow = async () => { // 新增
            setupContext.refs.advanceMultiDialog.show(true, config.contractAdvanceParams, {});
        };
        const contractSaveSure = async (data) => {
            contractRenewalRegistrationInsert(data).then(res => {
                if (res && +res.code === 0) {
                    Message.success('添加成功');
                }
            }).finally(() => {
                accountList.getList();
            });
            // let params = null;
            // if (Array.isArray(data) && data.length) {
            //     params = data.map((item) => {
            //         return {
            //             ...item,
            //             contractCode: props.detailData.contractCode,
            //             contractChangeCode: props.detailData.contractChangeCode ? props.detailData.contractChangeCode : null,
            //             // 合同“内外部业务”为内部时，合同分公司明细的“是否纯仓”赋默认值 N否，外部时不设置
            //             isPureWarehouse: item.isPureWarehouse || (props.detailData.oilIsGroupCustomer + '' === '0' ? 'N' : null)
            //         };
            //     });
            // }
            // if (!checkIsWarehouse(params)) {
            //     return false;
            // }
            // let res = '';
            // if (type && type === 'privilege') { // 特权新增
            //     res = await privilegeAddBatch(params);
            // } else if (props.detailPageState === STATE_OTHER_CHANGE) {
            //     res = await otherChangeCompanyAddBatch(params);
            // } else {
            //     res = await companyAddBatch(params);
            // }
            // if (res && +res.code === 0) {
            //     Message.success('保存成功');
            //     subTabTable.getList();
            // }
        };
        onActivated(() => {
            // setupContext.refs.editTableBox.$refs.moduleTable.doLayout(); // 解决表格错位
        });
        const dictData = ref({});
        onMounted(async() => {
            const res = await getDictDataApi({
                dictCodes: 'NEOCRM_CUSTOMER_LEVEL,SYS_BMS_BUSI_TYPE,CRM_CONTRACT_RENEWAL_RISK,NEOCRM_WH_TYPE,CRM_CONTRACT_RENEWAL_RISK,CRM_CONTRACT_RENEWAL_RISK_CAUSE'
            });
            dictData.value = res.data;
            const queryParams = {
                // effectTime: utils.defaultDate('365')
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
        });
        return {
            accountList,
            config,
            otpColumnConfig,
            showColumnConfigDialog,
            holePageLoading,
            utils,
            authorityCode,
            handleExport,
            rendertable,
            handleSort,
            codesToNames,
            handleFielChange,
            dictData,
            actionClick,
            addFileVisible,
            theRow,
            dialogConfirm,
            handleOpen,
            fileReadonly,
            removeTab,
            switchTabs,
            rowDBclick,
            activeTabs,
            tabList,
            downLoad,
            fileList,
            filesForm,
            addDialog,
            openImport,
            downloadLoading,
            closeLog,
            handleError,
            onChange,
            importLoading,
            handleImport,
            handleDeleteRows,
            handleAddRow,
            contractSaveSure,
            searchObj
        };
    }
};
</script>
<style lang="less">
.contract-manage {
    width: 100%;
    .contract-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }
    .contract-list-tab-pane.el-tabs {
        width: 100%;
        height: 100%;
        & > .el-tabs__content {
            height: calc(100% - 40px - 14px);
            overflow: auto;
            & > .el-tab-pane {
                overflow: auto;
                padding-right: 5px;
                height: 100%;
                .list-main {
                    .table-box {
                        height: 100%;
                    }
                }
            }
        }
    }
    .flex-layout .list-main {
        overflow: auto;
    }

    .flex-layout .table {
        overflow-y: auto;
    }
    .list-header {
        .contract-manage-list-dropdown.el-dropdown {
            color: #fff;
        }
    }

    .layout-content.contract-manage-paper {
        padding: 0px 20px 20px;
        .el-table .cell.el-tooltip {
            &>div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .list-layout-wrapper {
        padding: 0px;
    }
    .red-color {
        color: red;
    }
    .upload-btn {
        display: flex;
        // padding: 0px 0px 15px 80px;
        margin: 0 0 0 8px;
        .upload-demo {
            margin-left: 10px;
        }
    }
    .list-layout .list-header .el-input {
        margin-left: 0;
    }
}
.contract-manage-list-dropdown-menu {
    background-color: #fff;
}
.column-config_title {
    font-size: 14px;
}
</style>
